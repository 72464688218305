html {
  scroll-behavior: smooth;
}

/* li {
    color: black;
} */
/* 
ol {
    counter-reset: section;
    padding-left: 0;
}
li > ol {
    padding-left: 20px;
}
li > span {
    padding-left: 10px;
}
ol > li {
    display: block;
    color: black;
}
ol > li:before {
    content: counters(section, ".") ".    ";
    counter-increment: section;
} */
ul > li {
  color: black !important;
}
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
  color: black !important;
}
ol > li {
  display: table;
  color: black !important;
  counter-increment: item;
  margin-bottom: 0.5em;
}
ol > li:before {
  content: attr(data-indent-text) counters(item, ".") ")  ";
  display: table-cell;
  /* padding-right: 0.5em; */
  width: 30px;
  color: black !important;
}
ol > li > ul {
  color: black !important;
  padding-inline-start: 1rem;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
